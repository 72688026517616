import React from "react";
import Header from "../../../Component/Util/Header";
import FormEditProduk from "../../../Component/ProfileToko/Produk/FormEditProduk";

export default function EditProdukToko() {
  return (
    <>
      <Header />
      <FormEditProduk />
    </>
  );
}
